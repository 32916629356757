  Figma Styles of your File :root {
    /* Colors */
    --dulce: #FFC0CB;
    --salado: #ADD8E6;
    --picante: #FF4500;
    --amargo: #8A2BE2;
    --ácido: #FFA500;
    --agridulce: #FFD700;
    --frutal: #FFA07A;
    --cítrico: #FFFF00;
    --tropical: #FF6347;
    --fresco: #00FFFF;
    --mentolado: #00FA9A;
    --herbal: #32CD32;
    --especiado: #800000;
    --terroso: #8B4513;
    --floral: #FF69B4;
    --afrutado: #FF7F50;
    --nuez: #A52A2A;
    --caramelo: #D2691E;
    --chocolate: #5F9EA0;
    --vainilla: #FFF8DC;
    --canela: #D2B48C;
    --café: #8B4513;
    --miel: #DAA520;
    --coco: #FFF8DC;
    --naranja: #FFA500;
    --limón: #FFFF00;
    --fresa: #FF69B4;
    --plátano: #FFD700;
    --manzana: #32CD32;
    --uva: #800080;
    --pina: #FFD700;
    --cereza: #DC143C;
    --frambuesa: #FF1493;
    --arándano: #4169E1;
    --mango: #FFA500;
    --papaya: #FF4500;
    --sandía: #FF6347;
    --melon: #F08080;
    --piña: #FFD700;
    --pera: #DAA520;
    --kiwi: #32CD32;
    --durazno: #FFA07A;
    --albaricoque: #FFA07;
  }